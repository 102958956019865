@import 'Styles/includes';

.ThankYou {

    &:global {
        animation: fadein 0.2s ease-out;
    }

    &__Icon {
        margin: 45px auto 20px;
        display: block;
        height: 41px;
        width: 41px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../#{$publicpath}img/icon-thanks.svg');
    }

    &__Title {
        color: #0c0c0c;
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;
        max-width: 756px;
        margin: 0 auto 16px auto;
        line-height: 1.25;

        &--Sub {
            font-size: 1.8rem;
        }
    }

    &__Text {
        max-width: 756px;
        margin: 0 auto 30px;
        color: #0c0c0c;
        font-size: 1.6rem;
        line-height: 1.5;
        text-align: center;

        p {
            margin-bottom: 15px;
        }
    }

    &__Richtext {
        strong,
        b {
            font-weight: 700;
        }

        em,
        i {
            font-style: italic;
        }

        a {
            color: $link-color;
            text-decoration: none;
            display: inline-flex;
            align-items: baseline;
            position: relative;

            &[href^='http'] {
                &:after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background-image: url('../#{$publicpath}img/icon-external-link--red.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: bottom right;
                    margin-left: 6px;
                }
            }

            &[href^="https://cdn.sensus.se"],
            &[href^="https://stagecdn.sensus.se"],
            &[href*="sensus.se"]
            {
                &:after {
                    display: none;
                }
            }

            text-decoration: none;

            &:hover {
                text-underline-offset: 2px;
                text-decoration-thickness: 1px;
                text-decoration: underline;
            }
        }
    }

    &__Contact {
        margin-bottom: 40px;
        text-align: center;
    }

    &__ContactLink {
        color: $red;
        text-decoration: none;
        display: block;
    }

    &__FormSummary {
        margin-bottom: 30px;
    }

    &__FormSummarySection {
        margin-bottom: 20px;
    }

    &__FormValueText {
        display: inline-block;
        width: 50%;
        padding: 5px;
        vertical-align: top;

        &--Label {
            font-weight: bold;
            text-align: right;
        }
    }
}

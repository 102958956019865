@import 'Styles/includes';

.RegistrationNoSsnForm {
    &__ReserveRegistrationBox {
        width: 100%;
        text-align: center;
        padding: 0 8px;
        font-weight: bold;
        margin: 36px 0 48px 0;
        font-size: 1.8rem;
    }

    &__FormWrapper {
        padding-bottom: 120px;
    }

    &__HalfWrapper {
        @include media(md) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__Half {
        @include media(md) {
            width: calc(50% - 10px);
        }
    }

    &__Submit {
        margin-top: 20px;
    }

    &__Loader {
        display: flex;
        justify-content: center;
    }
}

@import 'Styles/includes';

/**
*
* ArrangementEntryList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ArrangementEntryList {
    $root: &;

    &__Container {
        position: relative;
    }

    &__Options {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        @include media(md) {
            flex-direction: row;
        }
    }

    &__Option {
        width: 100%;
        margin-bottom: 16px;
        text-align: left;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include media(md) {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
                margin-right: 8px;
            }

            &:last-of-type {
                margin-left: 8px;
            }
        }
    }

    &__Checkbox {
        position: relative;
        margin-top: 8px;
        padding: 4px 0;
        cursor: pointer;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            appearance: none;
        }

        label {
            padding-left: 32px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 22px;
                width: 22px;
                background-color: #e1e1e1;
                border-radius: 4px;
                transition: background-color .3s ease-in-out;
            }

            &::after {
                content: '';
                position: absolute;
                width: 17px;
                height: 17px;
                top: 2px;
                left: 2px;
                background-size: cover;
                background-repeat: no-repeat;
                background-image: url('#{$publicpath}img/icon-checkmark.svg');
                opacity: 0;
                transition: opacity .3s ease-in-out;
            }
        }

        input:checked + label {
            &::before {
                background-color: $red;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    &__LoaderWrapper {
        width: 35px;
        height: 35px;
        margin: 0 auto;
    }

    &__ButtonWrapper {
        display: flex;
        justify-content: center;
    }

    &__EntryList {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @include media(md) {
            margin-left: -24px;
            width: calc(100% + 24px);
        }
    }

    &__NoHits {
        text-align: center;
        margin-bottom: 24px;
    }

    &__Entry {
        width: 100%;
        margin-bottom: 15px;

        &:global {
            animation: slideDownFadeIn 250ms ease forwards;
        }

        @include media(md) {
            width: calc(100% / 2 - 24px);
            margin-bottom: 24px;
            margin-left: 24px;
        }

        @include media(lg) {
            width: calc(100% / 3 - 24px);
        }
    }

    &__GoToPage {
        display: inline-block;
        margin: 0 auto;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0.4px;
        color: $text;
        text-decoration-line: none;
        padding-right: 22px;
        background: transparent url('#{$publicpath}img/icon-arrow-thin.svg')
            no-repeat;
        background-position: right center;
        background-size: 14px;
    }
}

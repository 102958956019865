@import 'Styles/includes';

.Root {
    $root: &;

    margin-right: 24px;

    &__Label {
        position: relative;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 2px;
            top: 2px;
            height: 19px;
            width: 19px;
            border: 1px solid $gray-20;
            background: $gray-5;
            border-radius: 50%;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 8px;
            left: 8px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: white;
            opacity: 0;
        }

        #{$root}--Checked & {
            &::before {
                border-color: $radio-border;
                background-color: $radio-bg;
            }

            &::after {
                opacity: 1;
            }
        }

        #{$root}--Error:not(#{$root}--Checked) & {
            &::before {
                background-color: $error-bg;
                border-color: $error-color;
            }
        }

        #{$root}--Checked#{$root}--Error & {
            &::before {
                opacity: .6;
            }
        }

        #{$root}--Warning & {
            &::before {
                background-color: $warning-bg;
                border-color: $warning-color;
            }
        }
    }

    &__Text {
        line-height: 1.5;
        color: $text;
        font-size: 1.6rem;
        width: calc(100% - 35px);
        margin-left: 35px;
    }

    &__Error,
    &__Warning {
        font-size: 1.4rem;
        line-height: 1.75;
    }

    &__Error {
        color: $red;
    }

    &__Warning {
        color: $warning-color;
    }

    &__Input {
        display: none;
    }
}

@import 'Styles/includes';

.TextArea {
    width: 100%;
    margin-bottom: 24px;

    &__Label {
        display: block;
        position: relative;
        color: #0c0c0c;
        font-size: 1.6rem;
        line-height: 1.5;
        margin-bottom: 5px;
    }

    &__Description {
        font-size: 1.4rem;
        line-height: 1.5;
        color: #2f3035;
        margin-bottom: 6px;
    }

    &__Input {
        font-family: proxima-nova, Arial;
        border: 1px solid #dddddd;
        background: #f7f7f7;
        border-radius: 4px;
        font-size: 1.6rem;
        line-height: 1.5rem;
        outline: none;
        padding: 16px 12px;
        width: 100%;

        &::placeholder {
            font-style: italic;
        }

        &--Valid {
            background-color: #ecf7eb;
            border-color: #44af3a;
        }

        &--Warning {
            background-color: #fcfbed;
            border-color: #f8c614;
        }

        &--Error {
            background-color: rgba(175, 20, 50, 0.1);
            border-color: $red;
        }
    }

    &__Message {
        position: relative;
        background-color: #eeeeee;
        border-radius: 4px;
        color: #606060;
        font-size: 1.2rem;
        line-height: 1.5;
        padding: 13px 18px;
        margin-bottom: 8px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 42px;
            height: 42px;
            background-image: url('../#{$publicpath}img/softcorner.svg');
            top: -30px;
            right: 0px;
            background-size: cover;
        }
    }

    &__Error,
    &__Warning {
        font-size: 1.4rem;
        line-height: 1.75;
    }

    &__Error {
        color: $red;
    }

    &__Warning {
        color: #f8c614;
    }

    &__LabelRequired {
        color: $red;
    }

    &__LabelMessage {
        float: right;
        background-color: $gray-10;
        border-radius: 9px;
        cursor: pointer;
        font-size: 1.4rem;
        width: 18px;
        text-align: center;
        height: 19px;
        color: #7c7c7c;
        font-family: Georgia, serif;
        font-style: italic;
        font-weight: bold;
        line-height: 19px;
        position: absolute;
        right: 5px;
        top: 0;

        &--Active {
            color: white;
            z-index: 2;
            background-color: $red;
        }
    }
}

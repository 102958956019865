@import 'Styles/includes';

/**
*
* CardContactPersonList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardContactPersonList {
    $root: &;

    &__Container {
        //position: relative;
    }

    &__Options {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include media(md) {
            flex-direction: row;
        }
    }

    &__Category {
        display: inline-block;
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: left 16px center;
        //background-image: url('#{$publicpath}img/icon-location-black.svg');
    }

    &__Location {
        display: inline-block;
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: left 16px center;
        background-image: url('#{$publicpath}img/icon-location-black.svg');
    }

    &__Option {
        width: 100%;

        @include media(md) {
            width: auto;
        }

        &:first-of-type {
            margin-bottom: 16px;

            @include media(md) {
                margin-right: 16px;
                margin-bottom: 0;
            }
        }
    }

    &__LoaderWrapper {
        width: 35px;
        height: 35px;
        margin: 0 auto;
    }

    &__ButtonWrapper {
        display: flex;
        justify-content: center;
    }

    &__List {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @include media(md) {
            margin-left: -24px;
            width: calc(100% + 24px);
        }
    }

    &__NoHits {
        text-align: center;
        margin-bottom: 24px;
        line-height: 1.5;
        margin: 0 auto;

        a {
            text-decoration: none;
            color: $red;

            &:hover {
                text-underline-offset: 2px;
                text-decoration-thickness: 1px;
                text-decoration: underline;
            }
        }

        @include media(md) {
            max-width: 610px;
        }
    }

    &__Item {
        width: 100%;
        flex: 1;
        flex-basis: 100%;
        margin-bottom: 15px;
        background: white;

        @include media(sm) {
            min-width: calc(100% / 2 - 24px);
            flex-basis: 40%;
            width: auto;
            margin-bottom: 24px;
            margin-left: 24px;
        }

        @include media(lg) {
            min-width: calc(100% / 3 - 24px);
            flex-basis: 30%;
        }
    }

    &__SingleContainer {
        margin: 60px auto;

        @include media(md) {
            max-width: 756px;
        }
    }

    &__ButtonWrapper {
        display: flex;
        justify-content: center;
    }

    &__GoToPage {
        display: inline-block;
        margin: 0 auto;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0.4px;
        color: $text;
        text-decoration-line: none;
        padding-right: 22px;
        background: transparent url('#{$publicpath}img/icon-arrow-thin.svg')
            no-repeat;
        background-position: right center;
        background-size: 14px;
    }

}

@import 'Styles/includes';

/**
*
* PageRegion
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.PageRegion {
    $root: &;

    &__Content {
        margin: 0 15px;

        @include media(md) {
            margin: 64px 15px 0 15px;
        }
    }

    &__Information {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 0 auto;
        padding: 0 0 80px 0;

        @include media(md) {
            max-width: maxwidth(article);
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__Text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 24px 24px 0;

        @include media(md) {
            width: 50%;
        }
    }

    &__Title {
        font-weight: bold;
        font-size: 2.4rem;
        margin-bottom: 24px;
    }

    &__Text {
        font-size: 1.8rem;
        line-height: 1.5;
        letter-spacing: 0.2px;
    }

    &__CardRegion {
        width: 100%;
        box-shadow: 0 0 4px 0 rgba(black, 0.15);
        overflow: hidden;
        border-radius: 4px;

        @include media(md) {
            width: 365px;
        }
    }
}

@import 'Styles/includes';

/**
*
* PageResource
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.PageResource {
    &__Wysiwyg {
        padding: 24px 15px;

        @include media(md) {
            max-width: 756px;
            margin: 0 auto;
            padding: 48px 0;
        }
    }

    &__CardList {
        @include u-wrap(article);
        margin: 82px auto 0 auto;
    }

    &__Tag {
        font-size: 1.6rem;
        font-weight: 700;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 5px;
        line-height: 1.5;
        margin-bottom: 8px;
        letter-spacing: 0.4px;

        @include media(lg) {
            font-size: 1.8rem;
            margin-bottom: 8px;
        }
    }

    &__Title {
        font-weight: bold;
        line-height: 1.33;
        font-size: 2.4rem;

        @include media(lg) {
            font-size: 3.6rem;
        }
    }

    &__Header {
        padding-bottom: 16px;
        margin-bottom: 24px;
        text-align: center;
        border-bottom: 1px solid $gray-20;

        @include media(md) {
            max-width: 75%;
            padding-bottom: 32px;
            margin: 0 auto 48px auto;
        }
    }

    &__Resources {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @include media(md) {
            margin-left: -24px;
            width: calc(100% + 24px);
        }
    }

    &__Resource {
        width: 100%;
        margin-bottom: 15px;
        background: white;
        box-shadow: 0 0 4px 0 rgba(black, 0.15);
        border-radius: 4px;
        transition: box-shadow 0.15s ease;

        @include media(md) {
            width: calc(100% / 2 - 24px);
            margin-bottom: 24px;
            margin-left: 24px;
        }

        @include media(lg) {
            width: calc(100% / 3 - 24px);
        }

        &:hover {
            box-shadow: 0 0 6px 0 rgba(black, 0.3);

            @media (hover: none) {
                box-shadow: 0 0 4px 0 rgba(black, 0.15);
            }
        }
    }
}

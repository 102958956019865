@import 'Styles/includes';

/**
*
* SubMenu
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SubMenu {
    $root: &;

    position: relative;
    height: 45px;

    &__Content {
        padding: 14px 0;
        width: 100%;
        z-index: 1;
        background-color: $submenu-bg;
        color: white;
        transition: box-shadow 0.15s ease;
        will-change: box-shadow;
        box-shadow: 1px 7px 7px -3px rgba(black, 0);
        position: absolute;

        #{$root}--BreakpointXS & {
            @include media(xs) {
                padding: 0;
                position: relative;
            }
        }
        #{$root}--BreakpointMD & {
            @include media(md) {
                padding: 0;
                position: relative;
            }
        }

        &--Open {
            box-shadow: 1px 7px 7px -3px rgba(black, .25);

            #{$root}--BreakpointXS & {
                @include media(xs) {
                    box-shadow: none;
                }
            }
            #{$root}--BreakpointMD & {
                @include media(md) {
                    box-shadow: none;
                }
            }
        }

        &--Sticky {
            position: fixed;
            top: 0;
            box-shadow: 1px 3px 7px 0 rgba(black, .25);

            &:before {
                #{$root}--BreakpointXS & {
                    @include media(xs) {
                        content: '';
                        margin-top: 45px;
                    }
                }
                #{$root}--BreakpointMD & {
                    @include media(md) {
                        content: '';
                        margin-top: 45px;
                    }
                }
            }
        }
    }

    &__Title {
        display: flex;
        justify-content: space-between;
        color: white;
        align-items: center;
        font-size: 1.8rem;
        font-weight: 700;
        cursor: pointer;

        @include u-wrap(article);

        &:after {
            content: '';
            display: block;
            width: 24px;
            height: 20px;
            background-image: url('#{$publicpath}img/icon-arrow-down--white.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }

        &--Open {
            &:after {
                transform: rotate(180deg);
            }
        }

        #{$root}--BreakpointXS & {
            @include media(xs) {
                display: none;
            }
        }
        #{$root}--BreakpointMD & {
            @include media(md) {
                display: none;
            }
        }
    }

    &__Items {
        display: flex;
        visibility: hidden;
        opacity: 0;
        height: 0;
        transform: translateY(-10%);
        flex-direction: column;
        @include u-wrap(article);
        margin-top: 0;
        transition: margin-top .25s ease, opacity .25s ease, transform .25s ease;

        &--Open {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            height: auto;
            margin-top: 20px;
        }

        #{$root}--BreakpointXS & {
            @include media(xs) {
                flex-direction: row;
                margin-top: 0;
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
                height: auto;
            }
        }
        #{$root}--BreakpointMD & {
            @include media(md) {
                flex-direction: row;
                margin-top: 0;
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
                height: auto;
            }
        }
    }

    &__Item {
        position: relative;
        flex-shrink: 0;
        margin-left: -8px;

        #{$root}--BreakpointXS & {
            @include media(xs) {
                margin-left: 0;
            }
        }
        #{$root}--BreakpointMD & {
            @include media(md) {
                margin-left: 0;
            }
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $submenu-bg-hover;
            opacity: 0;
            transition: opacity .1s ease-in-out;
        }

        &::after {
            content: '';
            position: absolute;
            right: -7px;
            top: 25%;
            display: none;
            width: 1px;
            height: 50%;
            background: white;
            opacity: .35;
        }

        &--First {
            #{$root}--BreakpointXS & {
                @include media(xs) {
                    margin-left: -14px;
                    margin-right: 14px;
                    position: relative;

                    &::after {
                        display: block;
                    }
                }
            }
            #{$root}--BreakpointMD & {
                @include media(md) {
                    margin-left: -14px;
                    margin-right: 14px;
                    position: relative;

                    &::after {
                        display: block;
                    }
                }
            }
        }

        &--Overflow {
            #{$root}--BreakpointXS & {
                @include media(xs) {
                    visibility: hidden;
                }
            }
            #{$root}--BreakpointMD & {
                @include media(md) {
                    visibility: hidden;
                }
            }
        }

        &--Current {
            &::before {
                background: $submenu-bg-active;
                opacity: 1;
            }
        }

        &:not(#{$root}__Item--First):hover {
            &::before {
                opacity: 1;
            }
        }
    }

    &__Link {
        position: relative;
        padding: 8px;
        display: block;
        color: white;
        text-decoration: none;
        font-size: 1.4rem;
        line-height: 16px;
        font-family: proxima-nova, Arial;
        transition: color .1s ease-in-out;

        #{$root}--BreakpointXS & {
            @include media(xs) {
                padding: 14px;
                display: block;

                &--Sub {
                    color: black;
                    padding: 14px;

                    &:hover {
                        color: white;
                    }
                }

                &--First {
                    font-weight: 700;
                }
            }
        }
        #{$root}--BreakpointMD & {
            @include media(md) {
                padding: 14px;
                display: block;

                &--Sub {
                    color: black;
                    padding: 14px;

                    &:hover {
                        color: white;
                    }
                }

                &--First {
                    font-weight: 700;
                }
            }
        }

        &--Current {
            font-weight: 700;
        }
    }

    &__SubMenu {
        position: relative;
        display: none;

        #{$root}--BreakpointXS & {
            @include media(xs) {
                display: initial;
            }
        }
        #{$root}--BreakpointMD & {
            @include media(md) {
                display: initial;
            }
        }
    }

    &__SubMenuToggle {
        display: flex !important;
        background: none;
        border: none;
        margin: 0;
        cursor: pointer;

        &:after {
            content: '';
            margin-left: 8px;
            margin-top: 1px;
            display: block;
            width: 18px;
            height: 14px;
            background-image: url('#{$publicpath}img/icon-arrow-down--white.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }

        &--Open {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    &__SubMenuItems {
        position: absolute;
        border-radius: 4px;
        display: none;
        background-color: white;
        box-shadow: 0px 3px 5px 0px rgba(black, .25);
        margin-top: 5px;
        padding: 8px;
        width: 300px;
        right: 0;

        &--Open {
            display: block;

            &:before {
                content: '';
                position: absolute;
                top: -10px;
                right: 14px;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-top-width: 0;
                border-bottom-color: white;
            }
        }
    }
}
@import 'Styles/includes';

.EventInfo {
    background-color: $gray-10;
    padding: 35px 40px;
    text-align: center;
    margin: 30px auto 20px;

    &__Tagline {
        color: $red;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.4rem;
        letter-spacing: 1px;
        text-align: center;
    }

    &__Title {
        color: #0c0c0c;
        font-weight: bold;
        font-size: 2rem;
        line-height: 1.2;
        margin-top: 12px;
    }

    &__Information {
        opacity: 0.6;
        color: #0c0c0c;
        font-size: 1.4rem;
        line-height: 1.25;
        margin-top: 5px;
        text-align: center;

        span {
            white-space: nowrap;
        }
    }
}

@import 'Styles/includes';

.EventHeader {
    margin: 30px auto;

    &__Title {
        font-size: 2.4rem;
        line-height: 1.25;
        color: #0c0c0c;
        font-weight: bold;
    }

    &__Preamble {
        padding-top: 8px;
        color: black;
        opacity: 0.6;
        font-size: 2rem;
        line-height: 1.5;
    }
}

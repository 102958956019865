@import 'Styles/includes';

.Root {
    $root: &;

    &__Overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: black;
        opacity: .5;
        z-index: 1000;
    }

    &__Container {
        position: fixed;
        top: 30%;
        left: 50%;
        width: calc(100% - 20px);
        max-width: 460px;
        padding: 24px 16px;
        text-align: center;
        border-radius: 4px;
        background: white;
        box-shadow: -4px 4px 8px 0 rgba(black, .3);
        transform: translateX(-50%);
        z-index: 1001;

        @include media(sm) {
            max-width: 540px;
            padding: 32px 24px;
        }

        @include media(md) {
            padding: 40px 32px;
        }
    }

    &__Title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 16px;

        @include media(sm) {
            font-size: 2.4rem;
        }
    }

    &__Text {
        margin-bottom: 16px;
        line-height: 1.2;

        @include media(sm) {
            margin-bottom: 24px;
            font-size: 1.6rem;
        }
    }

    &__Button {
        &:not(:first-child) {
            margin-left: 8px;

            @include media(sm) {
                margin-left: 16px;
            }
        }
    }
}

@import 'Styles/includes';

/**
*
* SocialMediaLinks
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SocialMediaLinks {
    $root: &;

    &__Links {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    &__Link {
        width: 48px;
        height: 48px;
        margin-right: 24px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        &:last-of-type {
            margin-right: 0 !important;
        }

        #{$root}--Small & {
            width: 24px !important;
            height: 24px !important;

            &--Facebook {
                background-image: url('#{$publicpath}img/icon-facebook--white.svg');
            }

            &--Instagram {
                background-image: url('#{$publicpath}img/icon-instagram--white.svg');
            }

            &--Twitter {
                background-image: url('#{$publicpath}img/icon-twitter--white.svg');
            }

            &--LinkedIn {
                background-image: url('#{$publicpath}img/icon-linkedin--white.svg');
            }
        }

        #{$root}:not(#{$root}--Small) & {
            @include media(md) {
                margin-right: 64px;
                width: 64px;
                height: 64px;
            }

            &--Facebook {
                background-image: url('#{$publicpath}img/icon-facebook--black.svg');
            }

            &--Instagram {
                background-image: url('#{$publicpath}img/icon-instagram--black.svg');
            }

            &--Twitter {
                background-image: url('#{$publicpath}img/icon-twitter--black.svg');
            }

            &--LinkedIn {
                background-image: url('#{$publicpath}img/icon-linkedin--black.svg');
            }
        }
    }
}

@import 'Styles/includes';

/**
*
* NoSsnInfoBox
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.NoSsnInfoBox {
    &__NoSsnInfoBox {
        font-size: 1.4rem;
        background: #f7f7f7;
        padding: 16px;
        line-height: 1.25;
        border-radius: 8px;
        margin: 8px 0;

        &:global {
            animation: fadein 0.35s ease;
        }
    }

    &__NoSsnToggler {
        cursor: pointer;
        color: $red;
        font-size: 1.4rem;
        display: flex;
        align-items: center;

        &:after {
            content: '';
            margin-left: 4px;
            background-repeat: no-repeat;
            background-size: 10px;
            background-image: url('../#{$publicpath}img/icon-arrow-down.svg');
            width: 12px;
            height: 12px;
            display: inline-block;
            background-position: center 3px;
            border: 1px solid #d8d8d8;
            border-radius: 50%;
        }

        &--Open {
            &:after {
                transform: rotate(180deg);
            }
        }

        &:hover {
            text-decoration: underline;

            @media (hover: none) {
                text-decoration: none;
            }
        }
    }

    &__ContactPersons {
        margin-top: 16px;

        @include media(md) {
            margin-top: 0;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__ContactPerson {
        padding: 16px;
        margin: 8px 0 0 0;
        border: 1px solid #dddddd;
        width: 100%;
        background: white;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        display: flex;

        @include media(md) {
            margin: 16px 8px 0 0;
            width: calc(50% - 8px);

            &:nth-child(even) {
                margin: 16px 0 0 8px;
            }
        }
    }

    &__ContactTitle {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 12px;
    }

    &__ContactLink {
        color: $red;
        text-decoration: none;
        display: block;

        &--Phone {
            margin-bottom: 4px;
        }

        &:hover {
            text-decoration: underline;

            @media (hover: none) {
                text-decoration: none;
            }
        }
    }
}

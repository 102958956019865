@import 'Styles/includes';

.Root {
    $root: &;

    margin-bottom: 24px;

    &__Label {
        display: flex;
        line-height: 1.5;
        color: $text;
        font-size: 1.6rem;

        a {
            color: $link-color;
            text-decoration: none;
        }
    }

    &__Required {
        color: $error-color;
    }

    &__Choices {
        display: flex;
        margin-top: 8px;
    }

    &__Error,
    &__Warning {
        font-size: 1.4rem;
        line-height: 1.75;
    }

    &__Error {
        color: $error-color;
    }
}

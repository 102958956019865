@import 'Styles/includes';

.ResourceList {
    &__Link {
        margin-top: 24px;
        display: inline-block;
        text-decoration: none;
        font-size: 1.6rem;
        letter-spacing: 0.4px;
        font-weight: bold;
        color: #9c0a27;
        text-decoration: none;

        &:hover {
            text-underline-offset: 2px;
            text-decoration-thickness: 1px;
            text-decoration: underline;
        }
    }

    &__Content {
        width: 100%;

        @include media(md) {
            width: 100%;
            margin: 0 auto;
        }
    }

    &__Items {
        //border-radius: 4px;
        display: flex;
        flex-wrap: wrap;
        background-color: rgba($gray-10, 0.5);

        @include media(md) {
            width: 50%;
            margin: 0 auto;
        }
    }

    &__Item {
        width: 100%;
        border-bottom: 1px solid $gray-20;

        &:last-of-type {
            border-bottom: none
        }
    }
}

@import 'Styles/includes';

.OrgSelectButton {
    background-color: white;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    display: block;
    font-size: 1.6rem;
    line-height: 1.8rem;
    margin-bottom: 14px;
    padding: 18px 26px;
    width: 100%;
    cursor: pointer;

    &:hover {
        border: 1px solid #969696;

        @media (hover: none) {
            border: 1px solid #cfcfcf;
        }
    }

    strong {
        font-weight: 600;
        margin-bottom: 4px;
        display: inline-block;
    }

    &__Input {
        display: none;
    }

    &--Checked {
        background-color: #ecf7eb;
        border-color: #44af3a;
    }
}

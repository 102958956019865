@import 'Styles/includes';

/**
*
* CardRegion
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardRegion {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    &__Image {
        width: 250px;
        height: 250px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        overflow: hidden;
        margin-top: 24px;
    }

    &__Info {
        width: 100%;
        text-align: center;
        margin-top: 28px;
        background-color: $red;
        padding: 0 40px;

        @include media(xs) {
            padding: 0;
        }
    }

    &__ContactInfo {
        margin: 32px 0 32px;
    }

    &__ContactLink {
        text-decoration: none;
        color: white;
        font-size: 1.4rem;
        line-height: 1.5;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-right: 8px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &--Email {
            &:before {
                background-image: url('#{$publicpath}img/icon-email--white.svg');
            }
        }

        &--Phone {
            &:before {
                background-image: url('#{$publicpath}img/icon-old-phone--white.svg');
            }
        }
    }

    &__Label {
        font-size: 1.1rem;
        letter-spacing: 0.8px;
        color: white;
        margin-bottom: 31px;

        ::before {
            display: inline-block;
            position: relative;
            top: 2px;
            left: -4px;
            height: 12px;
            width: 10px;
            content: '';
            background-image: url('#{$publicpath}img/icon-location-white.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &__SocialMedia {
        margin: 32px auto;
    }
}

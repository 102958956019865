@import 'Styles/includes';

.ErrorList {
    background-color: rgba(175, 20, 50, 0.1);
    border-radius: 2px;
    margin-bottom: 16px;
    padding: 15px 25px;

    &__Title {
        font-size: 1.6rem;
        font-weight: bold;
    }

    &__List {
        list-style: disc;
        margin-left: 17px;
        margin-top: 4px;
    }

    &__Item {
        color: #2f3035;
        font-size: 1.4rem;
        line-height: 1.5;
    }
}

@import 'Styles/includes';

.RegistrationWithSsnForm {
    &__ReserveRegistrationBox {
        width: 100%;
        text-align: center;
        padding: 0 8px;
        font-weight: bold;
        margin: 36px 0 48px 0;
        font-size: 1.8rem;
    }

    &__FormWrapper {
        padding-bottom: 120px;
    }

    &__Payment {
        color: black;
        font-size: 16px;
        font-weight: 500;
        margin-left: 30px;
        text-decoration: underline;
        cursor: pointer;
    }

    &__FormSelectTitle {
        margin-bottom: 5px;
        line-height: 1.5;
        font-size: 1.6rem;
        color: #0c0c0c;
    }

    &__NoSsnInfoBoxWrapper {
        display: block;
        margin-top: -16px;
        margin-bottom: 16px;
    }

    &__Select {
        border: 1px solid #dddddd;
        background-color: #f7f7f7;
        border-radius: 4px;
        font-size: 1.6rem;
        outline: none;
        padding: 16px 12px;
        width: 100%;
        margin: 0 0 20px 0;
        appearance: none;
        position: relative;
        background-repeat: no-repeat;
        background-position: right 12px center;
        background-image: url('../#{$publicpath}img/icon-arrow-down.svg');
        background-size: 17px;
    }

    &__OptionalForm {

        &:global {
            animation: fadein 0.6s ease-out;
        }
    }

    &__Heading {
        display: block;
        margin-bottom: 10px;
        padding-top: 10px;
        font-weight: bold;
        font-size: 1.8rem;
    }

    &__SubTitle {
        margin-bottom: 5px;
        line-height: 1.5;
        font-size: 1.8rem;
        color: #0c0c0c;
        font-weight: bold;
    }

    &__InvoiceForm {
        margin: 20px 0;

        &:global {
            animation: fadein 0.6s ease-out;
        }
    }

    &__OrgInput {
        position: relative;
    }

    &__OrgOptions {
        margin-bottom: 20px;

        &:global {
            animation: fadein 0.6s ease-out;
        }
    }

    &__OrgTitle {
        margin-bottom: 14px;
        font-weight: bold;
    }

    &__OrgsNoMatch {
        margin-bottom: 16px;
    }

    &__Orgs {
        background-color: #f7f7f7;
        border-radius: 4px;
        padding: 30px 40px;
    }

    &__AddOrg {
        color: $red;
        text-decoration: none;

        &:hover {
            text-decoration: underline;

            @media (hover: none) {
                text-decoration: none;
            }
        }

        &--FoundOrganizations {
            margin-top: 20px;
        }
    }

    &__ButtonContainer {
        margin-bottom: 20px;
    }

    &__Payer {
        margin-bottom: 4px;
    }

    &__Required {
        color: $red;
    }

    &__Loader {
        display: flex;
        justify-content: center;
    }
}

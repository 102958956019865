@import 'Styles/includes';

.CheckBox {
    margin-bottom: 14px;

    &__Label {
        display: flex;
    }

    &__Text {
        line-height: 1.5;
        color: $text;
        font-size: 1.6rem;
        width: calc(100% - 35px);

        a {
            color: $blue;
            text-decoration: none;
        }
    }

    &__Box {
        position: relative;
        width: 35px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 23px;
            width: 23px;
            border: 1px solid $gray-20;
            background: $gray-5;
            border-radius: 4px;
        }

        &--Checked {
            &:before {
                background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2213%22%20height%3D%2210%22%20viewBox%3D%220%200%2013%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%203.5L5.5%208l6.002-7%22%20stroke-width%3D%222.5%22%20stroke%3D%22%23FFF%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
                background-repeat: no-repeat;
                background-position: center center;
                height: 22px;
                width: 22px;
                border: 1px solid $checkbox-border;
                border-radius: 4px;
                background-color: $checkbox-bg;
            }
        }

        &--Error {
            &:before {
                background-color: $error-bg;
                border-color: $error-color;
            }
        }

        &--Warning {
            &:before {
                background-color: $warning-bg;
                border-color: $warning-color;
            }
        }
    }

    &__Error,
    &__Warning {
        font-size: 1.4rem;
        line-height: 1.75;
    }

    &__Error {
        color: $error-color;
    }

    &__Warning {
        color: $warning-color;
    }

    &__Input {
        display: none;
    }
}

@import 'Styles/includes';

.Root {
    $root: &;

    &__Container {
        padding: 15px 0;
    }

    &__List {
        flex-wrap: wrap;
        margin-left: -24px;
        display: flex;
        justify-content: center;
    }

    &__Item {
        width: 100%;
        margin-bottom: 15px;
        margin-left: 24px;

        @include media(sm) {
            width: calc(50% - 24px);
            margin-bottom: 24px;
        }

        @include media(lg) {
            width: calc(#{percentage(1/3)} - 24px);
        }
    }
}

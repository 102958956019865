@import 'Styles/includes';

.LegalPaymentFields {

    &__InvoiceForm {
        margin: 20px 0;

        &:global {
            animation: fadein 0.6s ease-out;
        }
    }

    &__OrgInput {
        position: relative;
    }

    &__OrgOptions {
        margin-bottom: 20px;

        &:global {
            animation: fadein 0.6s ease-out;
        }
    }

    &__OrgTitle {
        margin-bottom: 14px;
        font-weight: bold;
    }

    &__OrgsNoMatch {
        margin-bottom: 16px;
    }

    &__Orgs {
        background-color: #f7f7f7;
        border-radius: 4px;
        padding: 30px 40px;
    }

    &__AddOrg {
        color: $red;
        text-decoration: none;

        &:hover {
            text-decoration: underline;

            @media (hover: none) {
                text-decoration: none;
            }
        }

        &--FoundOrganizations {
            margin-top: 20px;
        }
    }

    &__Error,
    &__Warning {
        font-size: 1.4rem;
        line-height: 1.75;
    }

    &__Error {
        color: $red;
    }
}

@import 'Styles/includes';

.PageEditorial {
    font-family: $base-font;

    &__Wysiwyg {
        padding: 24px 15px;

        @include media(md) {
            max-width: 756px;
            margin: 0 auto;
            padding: 48px 0;
        }
    }

    &__LinkWrapper {
        margin: 10px auto 47px;
        padding: 0 98px;

        @include media(md) {
            max-width: 756px;
            padding: 0;
        }
    }

    &__Related {
        @include u-wrap(site);
        margin-top: 24px;

        @include media(md) {
            margin-top: 56px;
        }
    }

    &__Link {
        display: inline-block;
        padding: 12px 29px 11px 24px;
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: 0.35px;
        color: white;
        border-radius: 4px;
        text-decoration: none;
        background-color: $red;

        &::after {
            display: inline-block;
            content: '';
            position: relative;
            left: 5px;
            width: 10px;
            height: 10px;
            background: transparent
                url('#{$publicpath}img/icon-external-link--white.svg') no-repeat;
            background-size: contain;
        }

        &:hover {
            background: $red-60;
            @media (hover: none) {
                background: $red;
            }
        }
    }
}
